$(function() {
    $(window).load(function() {
        var headerHeight=$("body > header").height()-55;
        var body=$("body");
        $(window).scroll(function(e) {
            var scrollTop=$([window, "html"]).scrollTop();
            if(scrollTop>=headerHeight && !body.hasClass("fixed-menu")) {
                body.addClass("fixed-menu");
            } else if(scrollTop<headerHeight && body.hasClass("fixed-menu")) {
                body.removeClass("fixed-menu");
            }
        });
    });
});